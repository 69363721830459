import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppService } from './services/app.service';

import { FormFieldTypes } from '@aws-amplify/ui-components';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    formFields: FormFieldTypes;
    title = 'amplify-angular-auth';
    user: CognitoUserInterface | undefined;
    authState: AuthState;

  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Booking',
      url: '/booking',
      icon: 'calendar'
    },
    {
      title: 'Invoices',
      url: '/billing',
      icon: 'reader'
    },
    {
      title: 'Forms',
      url: '/forms',
      icon: 'file-tray-full'
    },
    {
      title: 'Account',
      url: '/account',
      icon: 'person'
    },
    {
      title: 'Logout',
      url: '/login',
      icon: 'exit'
    }
  ];
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public app: AppService,
    private ref: ChangeDetectorRef
  ) {

      this.formFields = [
          {
              type: "email",
              label: "Custom email Label",
              placeholder: "custom email placeholder",
              required: true,
          },
          {
              type: "password",
              label: "Custom Password Label",
              placeholder: "custom password placeholder",
              required: true,
          },
          {
              type: "phone_number",
              label: "Custom Phone Label",
              placeholder: "custom Phone placeholder",
              required: false,
          },
      ];

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

    ngOnInit() {
    //const path = window.location.pathname.split('folder/')[1];
    //if (path !== undefined) {
    //  this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    //}

        onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            this.user = authData as CognitoUserInterface;
            console.log('user', this.user);
            var target = this.user.keyPrefix + '.' + this.user.username + '.accessToken';

            //console.log('key', this.user.keyPrefix + '.'+ this.user.username+'.accessToken');
            //console.log('CognitoIdentityServiceProvider.2hmaef653qk5dm7aliheq3h2vj.84dff13d-9095-49bf-900e-da2460e5a5d0.accessToken', this.user.pool.storage[target]);
            console.log('All Tokens', this.user.pool['storage']);
            console.log('token', this.user.pool['storage'][target]);

            localStorage['token'] = this.user.pool['storage'][target];
            this.app.sysData.token = this.user.pool['storage'][target];
            this.ref.detectChanges();
        })
    }

    ngOnDestroy() {
        return onAuthUIStateChange;
    }
}
