// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    identityPoolId: 'us-west-2:86c32752-41bd-432e-bc7a-1a7bdebc6ec7',
    cognitoClientId: '1eg214t902r7t90bjo7u8sakap',
    cognitoPoolId: 'us-west-2_4BHBD7g4Q',
    bucketName: 'awsfileuploaderstack-imagesthumbnailsbucketdev087-18603phpbcqwr',
    region: 'us-west-2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
