import { Injectable } from '@angular/core';
import { NavController, MenuController, ToastController, AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import * as tz from 'moment-timezone';
import { Auth } from 'aws-amplify';

declare var defaultSettings;


@Injectable({
  providedIn: 'root'
})
export class AppService {
    flags: any;
    settings: any;
    sysData = defaultSettings;
    locData = { id: null, location_id: null, timezone: null, users: [], products: [], categories: [], map: [], settings: null };

    constructor( private navCtrl: NavController, public toastController: ToastController, public alertController: AlertController, public modalController: ModalController) {
        const moment = import('moment-timezone');


        this.flags = {
            sideMenu: { title: 'Notifications', mode: 1, data: [] },
            notifications: [{ type: "started", data: "none" }],
            tasks: [{ type: "tast", data: "none" }],
            printData: { type: 1, json: null },
            navData: { m: null, params: null },
            defaultTimes: [],
            colors: [
                { id: null, color: "#69a9d1" },
                { id: null, color: "#69d1c0" },
                { id: null, color: "#69d179" },
                { id: null, color: "#d18a69" },
                { id: null, color: "#d169b2" },
                { id: null, color: "#8d69d1" },
                { id: null, color: "#697ed1" },
                { id: null, color: "#347eb3" },
                { id: null, color: "#69a9d1" },
                { id: null, color: "#69d1c0" },
                { id: null, color: "#69d179" },
                { id: null, color: "#d18a69" },
                { id: null, color: "#d169b2" },
                { id: null, color: "#8d69d1" },
                { id: null, color: "#697ed1" },
                { id: null, color: "#347eb3" },
                { id: null, color: "#69a9d1" },
                { id: null, color: "#69d1c0" },
                { id: null, color: "#69d179" },
                { id: null, color: "#d18a69" },
                { id: null, color: "#d169b2" },
                { id: null, color: "#8d69d1" },
                { id: null, color: "#697ed1" },
                { id: null, color: "#347eb3" }
            ],
        };

        this.settings = defaultSettings;


        if (localStorage['sysData']) { //loda previous sysdata
            this.sysData = JSON.parse(localStorage['sysData']);
        }
        
        if (localStorage['locData']) { //loda previous sysdata
            this.locData = JSON.parse(localStorage['locData']);
        }

        this.currentTime();
        //this.applyTheme(0)
    }

    currentTime() {
        this.flags.sysTime = Date.now();
        setInterval(() => {
            this.flags.sysTime = Date.now();
        }, 60 * 1000);
    }

    applyTheme(m) {
        if (m == 1) {
            if (this.sysData.theme == false) { // if its set go dark
                this.sysData.theme = true;
            } else {
                this.sysData.theme = false;
            }
            localStorage['sysData'] = JSON.stringify(this.sysData);
        }

        if (this.sysData.theme == false) { // if its set go dark
            document.body.setAttribute('class', 'dark');
        } else { //otherwise show light
            document.body.setAttribute('class', 'light');
        }
    }


    //HANDLE TOKEN
    async refreshToken() {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession['refreshToken'], (err, session) => {
                console.log('session', err, session);
                const { idToken, refreshToken, accessToken } = session;
                // do whatever you want to do now :)
                console.log('New Token', session);
            });
        } catch (e) {
            console.log('Unable to refresh Token', e);
        }
    }

    //START CALENDER RENDERS

    getDate(epoch) {
        if (epoch != null) {
            var today = new Date();
            epoch = today.getTime();
        }

        var date = new Date(parseFloat(epoch));
        var temp = (date.getMonth() + 1) + "/" +
            date.getDate() + "/" +
            date.getFullYear() + " " +
            date.getHours() + ":" +
            date.getMinutes();

        return { epoch: epoch, date: temp };
    }

    getCalData(epoch, m) {
        console.log('rendered time');

        //var obj = moment.tz(dateIn, this.locData.timezone);
        var obj = moment.tz(epoch, 'Europe/London');
        this.flags.zeroEpoch = +obj.unix() * 1000;
        

        //var d = new Date(epoch);
        //d.setHours(0, 0, 0, 0);
        //this.flags.zeroEpoch = d.getTime();


        if (m == 1) { // IF ITS TIME RENDER
            //start time render
            var time = [
                { id: 1, entries: [], hour: true, name: "12:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch },
                { id: 2, entries: [], hour: false, name: "12:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 1) },
                { id: 3, entries: [], hour: false, name: "12:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 2) },
                { id: 4, entries: [], hour: false, name: "12:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 3) },

                { id: 5, entries: [], hour: true, name: "1:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 4) },
                { id: 6, entries: [], hour: false, name: "1:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 5) },
                { id: 7, entries: [], hour: false, name: "1:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 6) },
                { id: 8, entries: [], hour: false, name: "1:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 7) },

                { id: 9, entries: [], hour: true, name: "2:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 8) },
                { id: 10, entries: [], hour: false, name: "2:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 9) },
                { id: 11, entries: [], hour: false, name: "2:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 10) },
                { id: 12, entries: [], hour: false, name: "2:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 11) },

                { id: 13, entries: [], hour: true, name: "3:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 12) },
                { id: 14, entries: [], hour: false, name: "3:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 13) },
                { id: 15, entries: [], hour: false, name: "3:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 14) },
                { id: 16, entries: [], hour: false, name: "3:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 15) },

                { id: 17, entries: [], hour: true, name: "4:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 16) },
                { id: 18, entries: [], hour: false, name: "4:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 17) },
                { id: 19, entries: [], hour: false, name: "4:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 18) },
                { id: 20, entries: [], hour: false, name: "4:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 19) },

                { id: 21, entries: [], hour: true, name: "5:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 20) },
                { id: 22, entries: [], hour: false, name: "5:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 21) },
                { id: 23, entries: [], hour: false, name: "5:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 22) },
                { id: 24, entries: [], hour: false, name: "5:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 23) },

                { id: 25, entries: [], hour: true, name: "6:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 24) },
                { id: 26, entries: [], hour: false, name: "6:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 25) },
                { id: 27, entries: [], hour: false, name: "6:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 26) },
                { id: 28, entries: [], hour: false, name: "6:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 27) },

                { id: 29, entries: [], hour: true, name: "7:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 28) },
                { id: 30, entries: [], hour: false, name: "7:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 29) },
                { id: 31, entries: [], hour: false, name: "7:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 30) },
                { id: 32, entries: [], hour: false, name: "7:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 31) },

                { id: 33, entries: [], hour: true, name: "8:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 32) },
                { id: 34, entries: [], hour: false, name: "8:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 33) },
                { id: 35, entries: [], hour: false, name: "8:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 34) },
                { id: 36, entries: [], hour: false, name: "8:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 35) },

                { id: 37, entries: [], hour: true, name: "9:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 36) },
                { id: 38, entries: [], hour: false, name: "9:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 37) },
                { id: 39, entries: [], hour: false, name: "9:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 38) },
                { id: 40, entries: [], hour: false, name: "9:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 39) },

                { id: 41, entries: [], hour: true, name: "10:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 40) },
                { id: 42, entries: [], hour: false, name: "10:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 41) },
                { id: 43, entries: [], hour: false, name: "10:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 42) },
                { id: 44, entries: [], hour: false, name: "10:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 43) },

                { id: 45, entries: [], hour: true, name: "11:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 44) },
                { id: 46, entries: [], hour: false, name: "11:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 45) },
                { id: 47, entries: [], hour: false, name: "11:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 46) },
                { id: 48, entries: [], hour: false, name: "11:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 47) },

                { id: 49, entries: [], hour: true, name: "12:00 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 48) },
                { id: 50, entries: [], hour: false, name: "12:15 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 49) },
                { id: 51, entries: [], hour: false, name: "12:30 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 50) },
                { id: 52, entries: [], hour: false, name: "12:45 AM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 51) },

                { id: 53, entries: [], hour: true, name: "1:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 52) },
                { id: 54, entries: [], hour: false, name: "1:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 53) },
                { id: 55, entries: [], hour: false, name: "1:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 54) },
                { id: 56, entries: [], hour: false, name: "1:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 55) },

                { id: 57, entries: [], hour: true, name: "2:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 56) },
                { id: 58, entries: [], hour: false, name: "2:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 57) },
                { id: 59, entries: [], hour: false, name: "2:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 58) },
                { id: 60, entries: [], hour: false, name: "2:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 59) },

                { id: 61, entries: [], hour: true, name: "3:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 60) },
                { id: 62, entries: [], hour: false, name: "3:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 61) },
                { id: 63, entries: [], hour: false, name: "3:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 62) },
                { id: 64, entries: [], hour: false, name: "3:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 63) },

                { id: 65, entries: [], hour: true, name: "4:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 64) },
                { id: 66, entries: [], hour: false, name: "4:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 65) },
                { id: 67, entries: [], hour: false, name: "4:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 66) },
                { id: 68, entries: [], hour: false, name: "4:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 67) },

                { id: 69, entries: [], hour: true, name: "5:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 68) },
                { id: 70, entries: [], hour: false, name: "5:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 69) },
                { id: 71, entries: [], hour: false, name: "5:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 70) },
                { id: 72, entries: [], hour: false, name: "5:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 71) },

                { id: 73, entries: [], hour: true, name: "6:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 72) },
                { id: 74, entries: [], hour: false, name: "6:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 73) },
                { id: 75, entries: [], hour: false, name: "6:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 74) },
                { id: 76, entries: [], hour: false, name: "6:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 75) },

                { id: 77, entries: [], hour: true, name: "7:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 76) },
                { id: 78, entries: [], hour: false, name: "7:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 77) },
                { id: 79, entries: [], hour: false, name: "7:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 78) },
                { id: 80, entries: [], hour: false, name: "7:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 79) },

                { id: 81, entries: [], hour: true, name: "8:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 80) },
                { id: 82, entries: [], hour: false, name: "8:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 81) },
                { id: 83, entries: [], hour: false, name: "8:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 82) },
                { id: 84, entries: [], hour: false, name: "8:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 83) },

                { id: 85, entries: [], hour: true, name: "9:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 84) },
                { id: 86, entries: [], hour: false, name: "9:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 85) },
                { id: 87, entries: [], hour: false, name: "9:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 86) },
                { id: 88, entries: [], hour: false, name: "9:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 87) },

                { id: 89, entries: [], hour: true, name: "10:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 88) },
                { id: 90, entries: [], hour: false, name: "10:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 89) },
                { id: 91, entries: [], hour: false, name: "10:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 90) },
                { id: 92, entries: [], hour: false, name: "10:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 91) },

                { id: 93, entries: [], hour: true, name: "11:00 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 92) },
                { id: 94, entries: [], hour: false, name: "11:15 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 93) },
                { id: 95, entries: [], hour: false, name: "11:30 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 94) },
                { id: 96, entries: [], hour: false, name: "11:45 PM", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (900000 * 95) },
            ];
            //end time render
            return time;
        }


        if (m == 2) { // RETURN DATES
            var date = [
                { id: 0, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch },
                { id: 1, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 1) },
                { id: 2, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 2) },
                { id: 3, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 3) },
                { id: 4, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 4) },
                { id: 5, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 5) },
                { id: 6, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 6) },
                { id: 7, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 7) },
                { id: 8, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 8) },
                { id: 9, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 9) },
                { id: 10, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 10) },
                { id: 11, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 11) },
                { id: 12, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 12) },
                { id: 13, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 13) },
                { id: 14, date: null, entries: [], name: "", status: 0, patient: null, epoch: +this.flags.zeroEpoch + (86400000 * 14) },
            ];

            for (var t in date) {
                date[t].date = this.calcDate(date[t].epoch, 0);
            }
            return date;
        }
    }

    calcDate(dateIn, m) {
        var weekdays = new Array(7);
        weekdays[0] = "Sunday";
        weekdays[1] = "Monday";
        weekdays[2] = "Tuesday";
        weekdays[3] = "Wednesday";
        weekdays[4] = "Thursday";
        weekdays[5] = "Friday";
        weekdays[6] = "Saturday";

        var months = new Array(12);
        months[0] = "January";
        months[1] = "February";
        months[2] = "March";
        months[3] = "April";
        months[4] = "May";
        months[5] = "June";
        months[6] = "July";
        months[7] = "August";
        months[8] = "September";
        months[9] = "October";
        months[10] = "November";
        months[11] = "December";


        //convert date obj to localized timezone
        //var obj = moment.tz(dateIn, this.locData.timezone);
        var obj = moment.tz(dateIn, 'Europe/London');
        var epoch = +obj.unix() * 1000;

        //get numeric date
        var dd = obj.date()
        var mm = (+obj.month()) + 1;
        var yyyy = obj.year();

        //get text date
        var day = weekdays[obj.day()];
        var month = months[obj.month()];

        //get time
        var hour = obj.hours();
        var min = obj.minutes();
        var sec = obj.seconds();
        var time = obj.format('hh:mm A');
        var meridiem = obj.format('A');

        //quick formats
        var cal = obj.format('YYYY-MM-DD');

        return {
            input: dateIn,
            epoch: epoch,
            cal: cal,
            timezone: 'Europe/London',
            dmy: dd + '/' + mm + '/' + yyyy,
            mdy: mm + '/' + dd + '/' + yyyy,
            dateText: day + ', ' + month + ' ' + dd + ', ' + yyyy,
            fullText: time + ' ' + day + ', ' + month + ' ' + dd + ', ' + yyyy,
            day: day,
            dayIndex: obj.day(),
            dd: dd,
            month: month,
            mm: mm,
            year: yyyy,
            time12: time,
            time24: hour + ':' + min,
            hour: hour,
            min: min,
            sec: sec,
            meridiem: meridiem
        };
    }
    //END CALENDAR RENDERS


    alert(m) { this.presentAlert(m); }
    toast(m, c) { this.presentToast(m, c); }

    async presentAlert(m) {
        const alert = await this.alertController.create({
            header: 'Alert',
            subHeader: 'Message',
            message: m,
            buttons: ['OK']
        });

        await alert.present();
    }

    async presentToast(m, c) {
        const toast = await this.toastController.create({
            message: m,
            duration: 2000,
            color: c
        });
        toast.present();
    }
}
