/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:469515bc-230b-45d9-826e-4eaab5035f34",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MAseyw79P",
    "aws_user_pools_web_client_id": "1mktbde95goolo1jcre9vr5m4n",
    "oauth": {}
};


export default awsmobile;
